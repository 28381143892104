const setVacancyRichData = (
  title,
  description,
  companyName,
  address,
  publishFrom,
  publishTo,
  salaryMin,
  salaryMax,
  employmentType,
  educationLevel
) => {
  const streetAddress = `${address.street || ''} ${address.houseNumber || ''}${
    address.houseNumberSuffix || ''
  }`.trim();
  const obj = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    datePosted: publishFrom,
    description,
    hiringOrganization: {
      '@type': 'Organization',
      name: companyName,
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        streetAddress: streetAddress || null,
        addressLocality: address.city,
        addressRegion: address.state,
        postalCode: address.zipcode,
        addressCountry: address.country,
      },
    },
    title,
    validThrough: publishTo,
    baseSalary: {
      '@type': 'MonetaryAmount',
      currency: 'EUR',
      value: {
        '@type': 'QuantitativeValue',
        minValue: salaryMin,
        maxValue: salaryMax,
        unitText: 'MONTH',
      },
    },
    employmentType: employmentType.toUpperCase().replace('-', '_'),
    educationRequirements: educationLevel || null,
  };

  // get rid of empty keys
  ['validThrough', 'educationRequirements'].forEach(key => {
    if (!obj[key]) delete obj[key];
  });

  return obj;
};

export default setVacancyRichData;
