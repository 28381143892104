/**
 * Return a text string containing the base class of the component
 * and conditionally extra added classes
 * @param {Object} styles - The styles module object
 * @param {Object} classes - Object containing all className strings
 */
const withExtraClasses = (styles, classes) =>
  classes
    .filter(className => !!className)
    .map(className => styles[className])
    .join(' ')
    .trim();

export default withExtraClasses;
