export const slugify = string =>
  string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');

const setVacancyUrl = (id, slug, city) =>
  `vacature/${slug}${city ? `-${slugify(city)}` : ''}/${id}`;

export default setVacancyUrl;
