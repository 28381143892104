import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { withExtraClasses } from '@utils';
import styles from './Button.module.scss';

const Button = ({ as, href, type, click, fullWidth, children }) => {
  const classes = withExtraClasses(styles, [
    'button',
    fullWidth && 'buttonFullWidth',
  ]);

  if (as === 'a' && href) {
    return (
      <Link href={href}>
        <a className={classes}>{children} &raquo;</a>
      </Link>
    );
  }

  if (as === 'button') {
    return (
      // eslint-disable-next-line react/button-has-type
      <button className={classes} onClick={click} type={type}>
        {children} &raquo;
      </button>
    );
  }

  return <span className={classes}>{children} &raquo;</span>;
};

export default Button;

Button.defaultProps = {
  as: 'a',
  type: 'button',
  fullWidth: false,
};

Button.propTypes = {
  as: PropTypes.oneOf(['a', 'button', 'span']),
  href: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  click: PropTypes.func,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
};
