import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export default function useVacancies({ companyId }) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [numberOfVacancies, setNumberOfVacancies] = useState();
  const [vacancies, setVacancies] = useState();
  const [vacancyCities, setVacancyCities] = useState();

  // Set the vacancyCities only once
  const handleSetVacancyCities = aggregations => {
    if (!vacancyCities)
      setVacancyCities(
        aggregations['city-agg']?.buckets?.map(city => city.key) || []
      );
  };

  // The vacancies are fetched via our own api route,
  // which in turn fetches the vacancies from elastic search
  const fetchVacancies = useCallback(
    async city => {
      setLoading(true);
      await axios
        .get(
          `/api/vacancies?companyId=${companyId}${city ? `&city=${city}` : ''}`
        )
        .then(({ data: { totalResults, results, aggregations } }) => {
          setNumberOfVacancies(totalResults.value);
          setVacancies(results);
          handleSetVacancyCities(aggregations);
        })
        .catch(error => {
          const message =
            error.message || 'Something went wrong, please try again later.';
          setError({ message });
        });
      setLoading(false);
    },
    [companyId, vacancyCities]
  );

  // On city select change, fetch new vacancies with city value supplied
  const handleSelectCity = ({ target: { value: city } }) => {
    fetchVacancies(city);
  };

  useEffect(() => {
    // Only fetch the vacancies once on load
    if (companyId && !vacancies) fetchVacancies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, vacancies]);

  return {
    loading,
    error,
    vacancies,
    vacancyCities,
    numberOfVacancies,
    handleSelectCity,
  };
}
